import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'

export default {
  getNotifications() {
    return axios.get(`${config.urlCore}/api/v3/notifications/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  save_token(token) {
    return axios.post(`${config.urlCore}/api/v3/notifications/save/token/`,
      {token},
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  remove_token(token) {
    return axios.post(`${config.urlCore}/api/v3/notifications/remove/token/`,
      {token},
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  deleteNotification(notification_uuid) {
    return axios.delete(`${config.urlCore}/api/v3/notifications/${notification_uuid}/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  markViewedNotification(notification_uuid) {
    return axios.get(`${config.urlCore}/api/v3/notifications/viewed/${notification_uuid}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  activityNotification(size_page = 20, page = 1) {
    return axios.get(`${config.urlCore}/api/v3/notifications/activity/?size_page=${size_page}&page=${page}`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  settingsNotifications() {
    return axios.get(`${config.urlCore}/api/v3/notifications/settings/`,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
  updateSettingsNotifications(formData) {
    return axios.put(`${config.urlCore}/api/v3/notifications/settings/`,
      formData,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
}
