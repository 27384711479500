import axios from './axios_interceptors'
import config from '@/services/config'

export const createDescription = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/create-description/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
};
export const createTitle = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/title-proposal/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
};
export const createImage = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/create-image/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}
export const createVariationsImage = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/create-variations/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getHistoryImages = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/request-images/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getDetailHistory = (uuid) => {
  return axios.get(`${config.urlCore}/api/v3/ai/request-images/${uuid}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getSummaryCredits = () => {
  return axios.get(`${config.urlCore}/api/v3/ai/summary-credits/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getCommunityImages = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/community-images/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getPopularThemes = () => {
  return axios.get(`${config.urlCore}/api/v3/ai/popular-themes/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const chat = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/chat-gpt/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const actionsMessage = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/actions-message/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}
export const getPriceActions = () => {
  return axios.get(`${config.urlCore}/api/v3/ai/price-actions/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const exportMessage = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/export/message/`,
    data,
    {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(error => {
      return error.response
    });
}

export const exportMessageTextIa = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/export/text/`,
    data,
    {
      headers: { 
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      responseType: 'blob',
    })
    .then(response => response.data)
    .catch(error => {
      return error.response
    });
}

export const getConversations = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/conversations/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const editTitleChat = (chat_uuid, data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/change-title/${chat_uuid}/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const generateText = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/generate-text/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getSocialTexts = (query = undefined) => {
  const url = `${config.urlCore}/api/v3/ai/get-texts/${query ? `?query=${query}` : ''}`
  return axios.get(url,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}
export const getSocialText = (social_text_uuid) => {
  return axios.get(`${config.urlCore}/api/v3/ai/social-text/${social_text_uuid}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getVideoTalks = (query) => {
  return axios.get(`${config.urlCore}/api/v3/ai/video-talks/?query=${query}`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const addPresenter = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/create-presenter/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getPresenters = () => {
  return axios.get(`${config.urlCore}/api/v3/ai/get-presenters/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}
export const deletePresenter = (presenter_uuid) => {
  return axios.get(`${config.urlCore}/api/v3/ai/delete-presenter/${presenter_uuid}/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const getVoices = () => {
  return axios.get(`${config.urlCore}/api/v3/ai/get-voices/`,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}

export const createTalk = (data) => {
  return axios.post(`${config.urlCore}/api/v3/ai/create-talk/`,
    data,
    {
      headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
    })
    .then(response => response.data.response)
    .catch(error => {
      return error.response
    });
}